<template>
  <MenuItemLink :item="myTownItem" />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { getCookie } from '../../../../utils/sso-cookie-helper'
import MenuItemLink from './MenuItemLink.vue'

const { item } = defineProps<{
  item: MenuItem
}>()

const currentTown = ref<string | undefined>(undefined)

onMounted(() => {
  const cookieVal = getCookie('CurrentCity')
  if (cookieVal) {
    currentTown.value = cookieVal
  }
})

const myTownTitle = computed(() => {
  if (!currentTown.value) {
    return item.title
  }
  const splitUpCookie = currentTown.value.split('')
  splitUpCookie[0] = splitUpCookie[0].toUpperCase()
  return splitUpCookie.join('')
})

const myTownItem = computed(() => {
  return {
    ...item,
    title: myTownTitle.value,
    url: currentTown.value ? `/gemeinde/${currentTown.value}` : item.url,
  }
})
</script>
