<template>
  <AdWrapper>
    <Navigation />

    <main
      id="main"
      tabindex="-1"
      class="max-w-5xl"
    >
      <slot />
    </main>

    <NavigationFooter />
  </AdWrapper>
</template>
