<template>
  <aside class="z-[5000004] flex flex-col gap-4 bg-white">
    <MainLine
      class="hidden md:flex"
      :is-open="true"
      :menu="true"
      :color="color"
      @toggle-menu="$emit('toggleMenu')"
    />

    <nav
      id="main-menu"
      aria-label="primary"
      class="flex flex-col gap-4 mx-2 mt-4 mb-4 overflow-y-scroll pb-60 scrollbar-hide"
    >
      <LoginSection />

      <SearchSection />

      <MenuSection
        class="nav-menu-group"
        :items="menuWithoutPotentialFooter"
        :first-level="true"
      />

      <MenuItemFooter
        v-if="footer"
        class="nav-menu-footer"
        :item="footer"
      />
    </nav>
  </aside>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useMenu } from '../../../../composables/useMenu'
import MainLine from '../Lines/MainLine.vue'
import LoginSection from './LoginSection.vue'
import MenuItemFooter from './MenuItemFooter.vue'
import MenuSection from './MenuSection.vue'
import SearchSection from './SearchSection.vue'

const { color = 'Default' } = defineProps<{
  color?: MenuColorOptions
}>()

defineEmits<{
  (event: 'toggleMenu'): void
}>()

const { getMainMenu } = useMenu()

const menu = computed(() => {
  return getMainMenu()
})

const footer = computed(() => {
  return menu.value.find(item => item.classes.includes('footer'))
})

const menuWithoutPotentialFooter = computed(() => {
  return menu.value.filter(item => !item.classes.includes('footer'))
})
</script>
