<template>
  <NavigationCustomFooter v-if="data" :items="data.menu" />
</template>

<script lang="ts" setup>
import getNavItemsQuery from '~/graphql/queries/navigation/GetNavMenuItemsByName.gql'

const { data } = useAsyncQuery<{ menu: MenuItem[] }>(
  getNavItemsQuery,
  {
    menu: 'vol-at-vodl-footer',
  },
)
</script>
