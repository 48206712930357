<template>
  <nav
    aria-label="promo"
    :class="colors.line"
    class="w-full typo-meta-2-bold md:h-[30px] h-6 px-4"
  >
    <ul class="flex items-center justify-around h-full text-xs uppercase md:gap-8 md:ml-auto">
      <li v-for="navItem in items" :key="navItem.title">
        <a :href="navItem.url">
          {{ navItem.title }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useMenuColor } from '../../../../composables/useMenuColor'

const props = defineProps<{
  navItems: MenuItem[]
  color: MenuColorOptions
}>()

const colors = useMenuColor('Top', props.color)

const items = computed(() => {
  return props.navItems
})
</script>
