<template>
  <li>
    <a
      :href="item.url"
      :target="isNotCurrentDomain ? '_blank' : '_self'"
      class="text-xs underline text-text"
      v-html="item.title"
    />
  </li>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

const { item } = defineProps<{
  item: MenuItem
}>()

const isNotCurrentDomain = ref(item.url?.includes('http'))

onMounted(() => {
  isNotCurrentDomain.value = item.url?.includes('http') && !item.url?.includes(window?.location?.hostname)
})
</script>
