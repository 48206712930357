<template>
  <li class="py-4 border-b border-white md:border-b-0">
    <div class="flex justify-between px-4">
      <nuxt-link
        :to="item.url"
        class="font-bold"
      >
        {{ item.title }}
      </nuxt-link>

      <ArrowDown
        class="w-4 h-4 md:hidden"
        :class="{ 'rotate-180': isOpen }"
        @click="isOpen = !isOpen"
      >
        click
      </ArrowDown>
    </div>

    <ul
      v-show="isOpen"
      v-if="item.children"
      class="px-6 md:!block md:px-4"
    >
      <NavigationCustomFooterItem
        v-for="childItem in item.children"
        :key="childItem.id"
        :item="childItem"
      />
    </ul>
  </li>
</template>

<script lang="ts" setup>
import ArrowDown from '~/assets/svg/ArrowDown.svg'

defineProps<{ item: MenuItem }>()

const isOpen = ref(false)
</script>
