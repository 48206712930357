<template>
  <div>
    <!-- This element works as a placeholder for the header, like this we can reattach the header -->
    <div
      ref="header"
      class="w-full h-12 md:h-[94px]"
    >
      <header
        :class="{
          'fixed top-0 navigation-offset-left right-0 left-0 max-w-screen-lg': isFixed,
          'relative': !isFixed,
        }"
        class="vol-navigation-z-index z-[500003] navigation-max-width"
      >
        <div class="relative">
          <TopLine
            class="hidden md:flex"
            :nav-items="getTopMenu()"
            :color="color"
          />

          <MainLine :color="color" @toggle-menu="toggleMenuWithScroll" />

          <div v-show="menuIsOpen">
            <Menu
              :color="color"
              class="w-full md:w-80 absolute md:right-0 md:top-[30px] h-screen"
              @toggle-menu="toggleMenu"
            />

            <!-- Desktop black out of background -->
            <div class="fixed inset-0 hidden bg-black opacity-50 md:block ignore-click-outside" @click="toggleMenu" />
          </div>
        </div>
      </header>
    </div>

    <BottomLine
      v-if="isHomePage"
      class="relative max-w-screen-lg z-[500002] navigation-max-width vol-navigation-z-index"
      :nav-items="getBottomMenu()"
    />
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, useDebounceFn } from '@vueuse/core'
import { computed, ref, useTemplateRef, watch } from 'vue'
import { useMenu } from '../../../composables/useMenu'
import { useSSO } from '../../../composables/useSSO'
import { useWindowOffset } from '../../../composables/useWindowOffset'
import Menu from './HamburgerMenu/Menu.vue'
import BottomLine from './Lines/BottomLine.vue'
import MainLine from './Lines/MainLine.vue'
import TopLine from './Lines/TopLine.vue'

const {
  color = 'Default',
  bottomMenu,
  topMenu,
  mainMenu,
  sessionCookie,
  evoSSOCookie,
  isHomePage = false,
} = defineProps<{
  color?: MenuColorOptions
  bottomMenu: MenuItem[]
  topMenu: MenuItem[]
  mainMenu: MenuItem[]
  sessionCookie?: string
  evoSSOCookie?: string
  isHomePage?: boolean
}>()

if (sessionCookie && evoSSOCookie) {
  useSSO().setSSOCookie(sessionCookie, evoSSOCookie)
}

const { getBottomMenu, getTopMenu, setMenu, toggleMenu, menuIsOpen } = useMenu()

setMenu(topMenu, mainMenu, bottomMenu)

const header = useTemplateRef('header')
onClickOutside(header, () => (menuIsOpen.value ? toggleMenu() : ''), {
  ignore: ref(['.ignore-click-outside', '.shepherd-modal-overlay-container']),
})

const offsetToTop = useWindowOffset(header)

function toggleMenuWithScroll() {
  if (offsetToTop.value && offsetToTop.value > 0) {
    header.value?.scrollIntoView({ behavior: 'smooth' })
  }

  toggleMenu()
}

const isFixed = computed(
  () => offsetToTop.value !== undefined && offsetToTop.value <= 0,
)

// this is a workaround for the possibility, that a ad is loading exactly when the user is opening the menu.
const triggerDebounceScroll = useDebounceFn(() => {
  if (offsetToTop.value && offsetToTop.value <= 0) {
    return
  }

  header.value?.scrollIntoView({ behavior: 'instant' })
}, 10)

watch(offsetToTop, (val) => {
  if (menuIsOpen.value && val && val > 0) {
    triggerDebounceScroll()
  }
})

const offsetToLeft = useWindowOffset(header, { from: 'left' })
const leftOffset = computed(() => {
  return `${Math.max(offsetToLeft.value ?? 0, 0)}px`
})
</script>

<style scoped>
.navigation-offset-left {
  left: v-bind(leftOffset);
}
</style>

<style>
:target {
  @apply scroll-mt-[88px] md:scroll-mt-[122px];
}

/* Add css selector to set max-width of nav based on full-page */
.vodl-page-wrapper--full-width .navigation-max-width {
  max-width: 100%;
}

.vodl-ad-wrapper.full-screen-layout .navigation-max-width {
  max-width: 100%;
}
</style>
