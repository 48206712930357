<template>
  <Default v-if="hasMyTown" :nav-item="parsedNavItem" />
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { getSlugForGRW, getTownship } from '../../../../../utils/myTownMapping'
import { getCookie } from '../../../../../utils/sso-cookie-helper'
import Default from './Default.vue'

const props = defineProps<{ navItem: MenuItem }>()

const currentTown = ref<string | undefined>(undefined)

onMounted(() => {
  const cookieVal = getCookie('CurrentCity')
  if (cookieVal) {
    currentTown.value = cookieVal
  }
})

const hasMyTown = computed(() => {
  return currentTown.value !== undefined
})

const currentTownForLinkParsed = computed(() => currentTown.value ? getSlugForGRW(currentTown.value) : '')

const myTownTitle = computed(() => {
  return currentTown.value ? getTownship(currentTown.value)?.title : ''
})

const myTownUrl = computed(() => {
  return currentTown.value ? `/themen/gemeindewahl-${currentTownForLinkParsed.value}` : ''
})

const parsedNavItem = computed(() => {
  return {
    ...props.navItem,
    url: myTownUrl.value,
    title: myTownTitle.value,
  }
})
</script>
