<template>
  <div
    id="nav-login-section"
    class="grid grid-cols-2 bg-white rounded-lg"
    data-allow-mismatch="children"
  >
    <component
      :is="item.action ? 'button' : 'a'"
      v-for="item in items"
      :key="item.title"
      :href="item.url"
      class="flex flex-col items-center"
      :class="item.class"
      @click="() => item.action && item.action()"
    >
      <component :is="item.icon" />

      <span class="typo-menu-items">{{ item.title }}</span>
    </component>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Bookmark from '../../../../assets/svg/Bookmark.svg'
import Profile from '../../../../assets/svg/Profile.svg'
import { useMenu } from '../../../../composables/useMenu'
import { useSSO } from '../../../../composables/useSSO'

const { toggleMenu } = useMenu()
const sso = useSSO()

function openLoginForm() {
  window.dispatchEvent(new Event('open_login_form'))
  toggleMenu()
}

const items = computed(() => {
  if (sso.isLoggedIn()) {
    return [
      { title: 'Mein Konto', icon: Profile, url: '/profil', class: '' },
      { title: 'Tipp geben', icon: Bookmark, url: '/tipp-geben', class: 'tipp-geben' },
    ]
  }

  return [
    { title: 'Login', icon: Profile, action: openLoginForm, class: '' },
    { title: 'Tipp geben', icon: Bookmark, url: '/tipp-geben', class: 'tipp-geben' },
  ]
})
</script>
