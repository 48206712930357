<template>
  <a
    :href="item.url"
    :target="isNotCurrentDomain ? '_blank' : '_self'"
    class="flex items-center p-2 rounded cursor-pointer typo-menu-items min-h-10"
    :class="item.classes"
    v-html="item.title"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

const { item } = defineProps<{
  item: MenuItem
}>()

const isNotCurrentDomain = ref(item.url?.includes('http'))

onMounted(() => {
  isNotCurrentDomain.value = item.url?.includes('http') && !item.url?.includes(window?.location?.hostname)
})
</script>
