<template>
  <div class="grid items-center h-12 grid-rows-1 px-4 grid-custom-col md:h-16 md:min-h-16" :class="colors.line">
    <div v-if="!menu" class="flex items-center gap-4 min-w-24">
      <div class="laendlepunkte-placeholder min-w-14" />

      <div class="hidden md:flex">
        <DefaultButton
          color="BlackYellow"
          :small="true"
          @click="openExternal('/tipp-geben')"
        >
          Tipp geben
        </DefaultButton>
      </div>
    </div>

    <a
      v-if="!menu"
      href="/"
      aria-label="home"
      class="w-32 md:w-44"
    >
      <Logo :class="colors.logo" />
    </a>

    <div class="flex gap-10 ml-auto">
      <div v-if="!menu" class="items-center hidden gap-4 md:block vol-mainline-button-vplus-test">
        <DefaultButton
          color="Red"
          :small="true"
          @click="openExternal(vplusLink)"
        >
          {{ vplusText }}
        </DefaultButton>
      </div>

      <div class="flex justify-center">
        <button
          id="nav-burger"
          type="button"
          :aria-expanded="!menuIsOpen"
          aria-controls="main-menu"
          aria-label="open menu"
          @click="$emit('toggleMenu')"
        >
          <Burger
            v-if="!menuIsOpen"
            aria-hidden="true"
            focusable="false"
            class="w-6 h-6"
          />
          <Close
            v-else
            aria-hidden="true"
            focusable="false"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import Burger from '../../../../assets/svg/Burger.svg'
import Close from '../../../../assets/svg/Close.svg'
import Logo from '../../../../assets/svg/Logo.svg'
import { useMenu } from '../../../../composables/useMenu'
import { useMenuColor } from '../../../../composables/useMenuColor'
import { useSSO } from '../../../../composables/useSSO'
import DefaultButton from '../../../shared/DefaultButton.vue'

const { color, menu = false } = defineProps<{
  menu?: boolean
  color: MenuColorOptions
}>()

defineEmits<{
  (event: 'toggleMenu'): void
}>()

const colors = useMenuColor('Main', color)

const { menuIsOpen } = useMenu()
const sso = useSSO()
const vplusText = ref('V+ Testen')
const vplusLink = ref('/abo-vplus?utm_source=vplus_vol&utm_medium=vplus_menuleiste_button&utm_campaign=vplus_abo')

onMounted(() => {
  const res = sso.getLocalUserStatus()

  if (res?.hasPlusAccess) {
    vplusText.value = 'MEIN V+'
    vplusLink.value = '/specials/vplus'
  }
})

function openExternal(url: string) {
  window.location.href = url
}
</script>

<style scoped>
.grid-custom-col {
  grid-template-columns: 1fr auto 1fr;
}
</style>
