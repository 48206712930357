export function useMenuColor(component: 'Main' | 'Top', version: MenuColorOptions) {
  const main = {
    Default: {
      logo: '',
      line: 'md:bg-main-600 bg-main-600',
    },
    Red: {
      logo: 'text-white',
      line: 'bg-rot-600 text-white',
    },
    White: {
      logo: 'text-black',
      line: 'md:bg-white bg-white',
    },
  }

  const top = {
    Default: {
      logo: '',
      line: 'bg-main-600 md:bg-black md:text-white',
    },
    Red: {
      logo: 'text-white',
      line: 'bg-rot-600 text-white',
    },
    White: {
      logo: 'text-black',
      line: 'md:bg-black bg-white md:text-white',
    },
  }

  return component === 'Main' ? main[version] : top[version]
}
