<template>
  <details
    :open="open"
    class="overflow-hidden cursor-pointer typo-menu-items"
    :name="name"
  >
    <summary
      class="flex items-center p-2 rounded"
      :class="[firstLevelClass, ...item.classes]"
    >
      <span
        v-if="item.icon"
        class="mr-2"
        v-html="item.icon"
      />

      <span v-html="item.title" />
    </summary>

    <MenuSection class="ml-7" :items="item.children" />
  </details>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import MenuSection from './MenuSection.vue'

const { item, firstLevel = false } = defineProps<{
  item: MenuItem
  firstLevel?: boolean
}>()

const open = computed(() => item.classes.includes('open'))

const firstLevelClass = computed(() => firstLevel ? 'bg-main-200' : '')

const name = firstLevel ? 'first-level' : item.title
</script>

<style scoped>
details summary {
  &::marker,
  &::-webkit-details-marker {
    display: none;
  }

  &::after {
    content: url('../../../../assets/svg/ArrowDown.svg');
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    transition: 0.4s transform ease;
  }
}

details[open]>summary::after {
  transform: rotate(180deg);
  transition: 0.4s transform ease;
}
</style>
