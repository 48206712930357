<template>
  <span class="flex items-center typo-meta-1-bold">
    <span v-if="isLive" class="mr-2 uppercase text-rot-600">Live</span>
    <a
      :href="navItem.url"
      class="flex items-center gap-1 px-1.5 py-0.5 text-black border rounded whitespace-nowrap border-grau-400"
    >
      <div v-if="isLive" class="h-2.5 w-2.5 rounded-full bg-rot-600 animate-pulse" />
      {{ navItem.title }}
      <span v-if="time > 0" class="font-normal">{{ time }} min</span>
    </a>
  </span>
</template>

<script setup lang="ts">
import { useTimeoutPoll } from '@vueuse/core'
import { onMounted, ref } from 'vue'

defineProps<{ navItem: MenuItem }>()

const isLive = ref(false)
const time = ref(0)

async function fetchTickaroo() {
  const response = await fetch(
    'https://www.vol.at/wp-json/russmedia-tickaroo/v1/get-latest/?id=64f1dd33ff38356d05a8f458&limit=2',
  )
  const data = await response.json()

  if (data.latest < 120) {
    time.value = data.latest
  }

  if (data.latest <= 10) {
    isLive.value = true
  }
}

onMounted(() => {
  useTimeoutPoll(fetchTickaroo, 60000, {
    immediate: true,
  })
})
</script>
