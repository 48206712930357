<template>
  <div class="relative max-w-5xl 3xl:mx-auto">
    <AdSlot ad-slot="skyscraper_1" />
    <AdSlot ad-slot="skyscraper_left" />
    <AdSlot ad-slot="banner_1" />
    <AdSlot ad-slot="bigsizebanner_1" />
    <slot />
  </div>
</template>

<script setup lang="ts">
const { setConfig } = useAd()

// const baseConfig = {
//   setup: {
//     moca: {
//       url: 'https://mocafirst.at',
//       id: 1000004,
//     },
//   },
//   loops: {
//     list: {
//       slots: [
//         {
//           id: 'superlargead_1',
//           type: 'moca',
//           row: 1,
//           css_class: 'vodl-ad__newsriver',
//           moca_id: 2000012,
//         },
//       ],
//     },
//   },
//   page: {
//     slots: [
//       {
//         id: 'bigsizebanner_1',
//         type: 'moca',
//         css_class: 'vodl-ad__bigsizebanner',
//         moca_id: 2000314,
//         props: {
//           fixed_size: {
//             'enabled': true,
//             'min-height': 250,
//           },
//         },
//       },
//       {
//         id: 'skyscraper_left',
//         type: 'moca',
//         css_class: 'vodl-ad__skyscraper-left',
//         moca_id: 2000424,
//       },
//       {
//         id: 'skyscraper_1',
//         type: 'moca',
//         css_class: 'vodl-ad__halfpagead',
//         moca_id: 2000315,
//       },
//       {
//         id: 'contentad_1',
//         type: 'moca',
//         css_class: 'vodl-ad__sidebar___',
//         moca_id: 2000316,
//         props: {
//           fixed_size: {
//             'enabled': true,
//             'min-height': 250,
//           },
//         },
//       },
//       {
//         id: 'banner_1',
//         type: 'moca',
//         mobile: true,
//         moca_id: 2000326,
//         props: {
//           fixed_size: {
//             'enabled': true,
//             'min-height': 120,
//           },
//         },
//       },
//     ],
//   },
// } as AdConfig

const articleConfig = {
  setup: {
    outbrain: {
      token_id: 'fac4c4ac',
      premium: true,
      with_consent: true,
      lazy: true,
      lazy_margin: 1500,
    },
    apa: {
      lazy: true,
    },
    moca: {
      url: 'https://mocafirst.at',
      id: 1000004,
    },
  },
  loops: {
    content: {
      slots: [
        {
          id: 'contentad_2',
          type: 'moca',
          premium: false,
          row: 2,
          moca_id: 2000317,
        },
        {
          id: 'contentad_3',
          type: 'moca',
          premium: false,
          row: 5,
          moca_id: 2000562,
        },
        {
          id: 'contentad_4',
          type: 'moca',
          premium: false,
          row: 8,
          moca_id: 2000563,
        },
        {
          id: 'contentad_5',
          type: 'moca',
          premium: false,
          row: 11,
          moca_id: 2000564,
        },
        {
          id: 'contentad_6',
          type: 'moca',
          premium: false,
          row: 14,
          moca_id: 2000565,
        },
        {
          id: 'bannerparagraph_1',
          type: 'moca',
          premium: false,
          row: 2,
          mobile: true,
          moca_id: 2000329,
        },
        {
          id: 'bannerparagraph_2',
          type: 'moca',
          premium: false,
          row: 5,
          mobile: true,
          moca_id: 2000566,
        },
        {
          id: 'bannerparagraph_3',
          type: 'moca',
          premium: false,
          row: 8,
          mobile: true,
          moca_id: 2000567,
        },
        {
          id: 'bannerparagraph_4',
          type: 'moca',
          premium: false,
          row: 11,
          mobile: true,
          moca_id: 2000568,
        },
        {
          id: 'bannerparagraph_5',
          type: 'moca',
          premium: false,
          row: 14,
          mobile: true,
          moca_id: 2000569,
        },
      ],
    },
  },
  page: {
    slots: [
      {
        id: 'bigsizebanner_1',
        type: 'moca',
        css_class: 'vodl-ad__bigsizebanner',
        moca_id: 2000314,
        props: {
          fixed_size: {
            'enabled': true,
            'min-height': 250,
          },
        },
      },
      {
        id: 'skyscraper_1',
        type: 'moca',
        css_class: 'vodl-ad__halfpagead',
        moca_id: 2000315,
      },
      {
        id: 'skyscraper_left',
        type: 'moca',
        css_class: 'vodl-ad__skyscraper-left',
        moca_id: 2000424,
      },
      {
        id: 'contentad_1',
        type: 'moca',
        premium: false,
        moca_id: 2000316,
        props: {
          fixed_size: {
            'enabled': true,
            'min-height': 158,
          },
        },
      },
      {
        id: 'bannerparagraph_0',
        type: 'moca',
        mobile: true,
        moca_id: 2000328,
        props: {
          fixed_size: {
            'enabled': true,
            'min-height': 250,
          },
        },
      },
      {
        id: 'bigbanner_1',
        type: 'moca',
        premium: true,
        moca_id: 2000318,
      },
      {
        id: 'outbrain_after_article',
        type: 'outbrain',
        template: 'with_consent',
        props: {
          'widget-ids': 'AR_1',
        },
      },
      {
        id: 'outbrain_after_article_2',
        type: 'outbrain',
        template: 'with_consent',
        props: {
          'widget-ids': 'AR_2',
        },
      },
      {
        id: 'banner_1',
        type: 'moca',
        mobile: true,
        moca_id: 2000326,
        props: {
          fixed_size: {
            'enabled': true,
            'min-height': 120,
          },
        },
      },
      {
        id: 'banner_2',
        type: 'moca',
        mobile: true,
        moca_id: 2000327,
      },
      {
        id: 'video_1',
        type: 'moca',
        moca_id: 2000320,
      },
      {
        id: 'video-booster',
        type: 'apa-video-booster',
        props: {
          headline: 'Dieses Video könnte dich auch interessieren!',
          id: 'theothervidcontrol',
        },
      },
      {
        id: 'mobilevideo_1',
        type: 'moca',
        mobile: true,
        moca_id: 2000334,
      },
      {
        id: 'brandedcontent_1',
        type: 'moca',
        moca_id: 2000319,
      },
      {
        id: 'mobilebrandedcontent_1',
        type: 'moca',
        mobile: true,
        moca_id: 2000335,
      },
    ],
  },
} as any as AdConfig

setConfig(articleConfig)
</script>
