<template>
  <NPCommonNavigation
    :bottom-menu="bottom"
    :top-menu="top"
    :main-menu="main"
    :is-home-page="isHomePage"
  />
</template>

<script lang="ts" setup>
import getNavItemsQuery from '~/graphql/queries/navigation/GetSpecificNavItems.gql'

const config = useRuntimeConfig()

const { data: menu } = useAsyncQuery<
  {
    top: MenuItem[]
    main: MenuItem[]
    bottom: MenuItem[]
  }
>(
  getNavItemsQuery,
  {
    topMenu: config.public.menu.top,
    mainMenu: config.public.menu.main,
    bottomMenu: config.public.menu.bottom,
  },
)

const top = computed(() => menu?.value?.top ?? [])
const main = computed(() => menu?.value?.main ?? [])
const bottom = computed(() => menu?.value?.bottom ?? [])

const route = useRoute()

const isHomePage = computed(() => route.path === '/')
</script>
