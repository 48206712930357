<template>
  <li>
    <component
      :is="menuItemComponent"
      :item="item"
      :first-level="firstLevel"
      class="select-none"
    />
  </li>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import MenuItemLink from './MenuItemLink.vue'
import MenuItemMyTown from './MenuItemMyTown.vue'
import MenuItemWithChildren from './MenuItemWithChildren.vue'

const { item, firstLevel = false } = defineProps<{
  item: MenuItem
  firstLevel?: boolean
}>()

const hasChildren = item.children?.length > 0

const isMyTown = item.classes.includes('mytown')

const menuItemComponent = computed(() => {
  if (isMyTown) {
    return MenuItemMyTown
  }

  if (hasChildren) {
    return MenuItemWithChildren
  }

  return MenuItemLink
})
</script>
