<template>
  <footer class="w-full max-w-screen-lg">
    <div class="w-full h-6 bg-black">
      <LogoTopDesktop class="w-32 h-6 ml-6 text-white md:w-44" />
    </div>
    <div class="pt-2 bg-main-600">
      <Logo class="w-32 ml-6 md:w-44" />

      <nav class="pb-4 mt-4">
        <ul class="md:justify-around md:flex">
          <NavigationCustomFooterItemSection
            v-for="item in items"
            :key="item.id"
            :item="item"
          />
        </ul>
      </nav>
    </div>
    <div class="pt-4 pb-6 text-center bg-main-600">
      VOL.AT &#169; {{ year }}
    </div>
  </footer>
</template>

<script lang="ts" setup>
import Logo from '~/assets/svg/Logo.svg'
import LogoTopDesktop from '~/assets/svg/LogoTopDesktop.svg'

const { items } = defineProps<{ items: MenuItem[] }>()

const year = new Date().getFullYear()
</script>
