import type { Ref } from 'vue'
import { useEventListener, useResizeObserver } from '@vueuse/core'
import { onMounted, ref, watch } from 'vue'

export function useWindowOffset(target: Ref<HTMLElement | null>, options: { from: 'top' | 'bottom' | 'left' | 'right' } = { from: 'top' }) {
  const val = ref(target.value?.getBoundingClientRect()[options.from])

  const update = () => {
    val.value = target.value?.getBoundingClientRect()[options.from]
  }

  useEventListener('scroll', update, { passive: true })
  onMounted(() => {
    useResizeObserver(document.body, () => update())
  })

  watch(target, update)

  return val
}
