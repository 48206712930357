<template>
  <SearchBox
    id="nav-search"
    v-model="searchValue"
    color="bg-main-200"
    @search-entered="search"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue'
import SearchBox from '../../Search/SearchBox.vue'

const searchValue = ref('')
function search(value: string) {
  window.location.href = `/suche?search=${value}&page=1&per_page=10&orderby=date`
}
</script>
