<template>
  <li v-if="showItem">
    <component
      :is="navItemType"
      class="min-h-[27px]"
      :nav-item="navItem"
    />
  </li>
</template>

<script setup lang="ts">
import type { Component } from 'vue'
import { computed, onMounted, ref } from 'vue'
import { getCookie } from '../../../../utils/sso-cookie-helper'
import Default from './Type/Default.vue'
import GRW2025 from './Type/GRW2025.vue'
import Tickaroo from './Type/Tickaroo.vue'

const props = defineProps<{ navItem: MenuItem }>()

const customNavItems: Record<string, Component> = {
  tickaroo: Tickaroo,
  grw2025: GRW2025,
}

const navItemType = customNavItems[props.navItem.classes[0]] ?? Default

// remove after grw2025
const currentTown = ref<string | undefined>(undefined)

onMounted(() => {
  const cookieVal = getCookie('CurrentCity')
  if (cookieVal) {
    currentTown.value = cookieVal
  }
})

const hasMyTown = computed(() => {
  return currentTown.value !== undefined
})

const showItem = computed(() => {
  if (props.navItem.classes[0] === 'grw2025' && !hasMyTown.value) {
    return false
  }

  return true
})
</script>
