import { ref } from 'vue'

// @todo should be moved to pinia or nuxt store if switching to ssr
const topMenu = ref<MenuItem[]>([])
const mainMenu = ref<MenuItem[]>([])
const bottomMenu = ref<MenuItem[]>([])

const menuIsOpen = ref(false)

export function useMenu() {
  const setMenu = (top: MenuItem[], main: MenuItem[], bottom: MenuItem[]) => {
    topMenu.value = top
    mainMenu.value = main
    bottomMenu.value = bottom
  }

  const getTopMenu = (): MenuItem[] => {
    return topMenu.value
  }

  const getBottomMenu = (): MenuItem[] => {
    return bottomMenu.value
  }

  const getMainMenu = (): MenuItem[] => {
    return mainMenu.value
  }

  const toggleMenu = () => {
    menuIsOpen.value = !menuIsOpen.value

    if (menuIsOpen.value) {
      document.documentElement.style.overflow = 'hidden'
      document.documentElement.style.height = '100%'
      document.documentElement.style.margin = '0'
    }
    else {
      document.documentElement.style.removeProperty('overflow')
      document.documentElement.style.removeProperty('height')
      document.documentElement.style.removeProperty('margin')
    }
  }

  return {
    getTopMenu,
    getMainMenu,
    getBottomMenu,
    setMenu,
    toggleMenu,
    menuIsOpen,
  }
}
